import React from "react";

import GridRow from "../../commons/GridRow";
import Box from "../../commons/Box";
import Image from "../../commons/Image";
import Text from "../../commons/Text";
import Button from "../../commons/Button";
import RichTextRenderer from "../../commons/RichTextRenderer";
import { gradientBgVertical } from "../../../utils/styles";
import { linkResolver } from "../../../utils/links";

export default function ItemGridBlock({
  _rawTitle,
  buttons,
  items,
  style,
  ...otherProps
}) {
  return (
    <GridRow
      as="section"
      sx={{
        py: [5, "gridGap"],
        textAlign: ["center", "start"],
        color: "inverted",
        ...(style === "gradient"
          ? gradientBgVertical
          : { backgroundColor: "blue" }),
      }}
    >
      <GridRow.Col>
        <RichTextRenderer blocks={_rawTitle} />
        {!!buttons.length && (
          <Box sx={{ mt: 4 }}>
            {buttons.map(linkResolver).map((link) => (
              <Button key={link.to || link.href} {...link} />
            ))}
          </Box>
        )}
      </GridRow.Col>
      <GridRow.Col sx={{ mt: [4, 0] }}>
        <GridRow subgrid variant="fiveColumns">
          {items.map((item) => (
            <Box sx={{ textAlign: "center" }} key={item._key}>
              {item.image && item.image.asset && (
                <Image
                  fluid={item.image.asset.fluid}
                  alt={item.image.alt}
                  sx={{ mb: 3 }}
                />
              )}
              <Text variant="h5">{item.description}</Text>
            </Box>
          ))}
        </GridRow>
      </GridRow.Col>
    </GridRow>
  );
}
